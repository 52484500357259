import React, { useState, useEffect, FormEvent, useMemo } from 'react';
import axios from 'axios';
import {
  Container,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Link,
  Box,
  LinearProgress,
  LinearProgressProps,
} from '@material-ui/core';
import AddBox from '@material-ui/icons/AddBox';
import Search from '@material-ui/icons/Search';
import Games from '@material-ui/icons/Games';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import { IconMaterialTable } from './IconMaterialTable';
import { Column, Action } from '@material-table/core';
import { Refresh } from '@material-ui/icons';

const API_URL =
  process.env.NODE_ENV === 'production' ? 'https://backlog.alexmclean.xyz/api' : 'http://localhost:3000/api';

type StatusEnum = 'not-started' | 'started' | 'base-complete' | 'full-complete' | 'benched';
type PriorityEnum = 'done' | 'very-low' | 'low' | 'medium-low' | 'medium' | 'medium-high' | 'high' | 'very-high';

interface GameResponse {
  id: number;
  aggregated_rating?: number;
  aggregated_rating_count?: number;
  cover_url?: string;
  first_release_date?: number;
  name: string;
  rating?: number;
  rating_count?: number;
  slug: string;
  total_rating?: number;
  total_rating_count?: number;
  status: StatusEnum;
  desired_status: StatusEnum;
  priority: PriorityEnum;
  my_rating?: number;
  platforms: {
    id: number;
    abbreviation?: string;
    generation?: number;
    name: string;
    platform_logo_url?: string;
    slug: string;
  }[];
  hltbEntries: {
    id: number;
    game_id: number;
    name: string;
    image_url?: string;
    gameplay_main?: string;
    gameplay_main_extra?: string;
    gameplay_completionist?: string;
    gameplay_main_label?: string;
    gameplay_main_extra_label?: string;
    gameplay_completionist_label?: string;
    similarity: number;
    chosen: boolean;
  }[];
}

interface ParsedGameResponse extends GameResponse {
  platformString: string;
  relevantHltb?: number;
  hltbMain?: number;
  hltbMainLabel: string;
  hltbMainExtra?: number;
  hltbMainExtraLabel: string;
  hltbCompletionist?: number;
  hltbCompletionistLabel: string;
}

export interface SearchedGameResponse {
  id: number;
  aggregated_rating?: number;
  aggregated_rating_count?: number;
  cover?: {
    id: number;
    url: string;
  };
  first_release_date?: number;
  name: string;
  rating?: number;
  rating_count?: number;
  slug: string;
  total_rating?: number;
  total_rating_count?: number;
  platforms: number[];
}

interface LinearProgressWithLabelProps extends Omit<LinearProgressProps, 'variant'> {
  leftLabel: string;
  rightLabel: string;
}
const LinearProgressWithLabel = (props: LinearProgressWithLabelProps) => {
  const { leftLabel, rightLabel, ...progressProps } = props;

  return (
    <Box display="flex" alignItems="center">
      <Box minWidth={50}>
        <Typography variant="body2" color="textSecondary">
          {leftLabel}
        </Typography>
      </Box>
      <Box flexGrow={1} mr={1} ml={1}>
        <LinearProgress variant="determinate" {...progressProps} />
      </Box>
      <Box minWidth={100}>
        <Typography variant="body2" color="textSecondary">
          {rightLabel}
        </Typography>
      </Box>
    </Box>
  );
};

interface GameProgressProps {
  games: ParsedGameResponse[];
}
const GameProgress = (props: GameProgressProps) => {
  const { games } = props;

  const stats = useMemo(() => {
    return games.reduce(
      (acc, game) => {
        if (game.desired_status !== 'base-complete' && game.desired_status !== 'full-complete') return acc;

        if (game.status === 'benched') {
          return {
            ...acc,
            benchedGames: acc.benchedGames + 1,
            benchedHours: game.hltbMain ?? 0,
          };
        }

        const isComplete = game.status === game.desired_status;

        const desiredHours =
          game.desired_status === 'full-complete' ? game.hltbCompletionist ?? game.hltbMain ?? 0 : game.hltbMain ?? 0;
        const playedHours =
          game.status === 'full-complete'
            ? game.hltbCompletionist ?? game.hltbMain ?? 0
            : game.status === 'base-complete'
            ? game.hltbMain ?? 0
            : 0;

        return {
          ...acc,
          gamesTotal: acc.gamesTotal + 1,
          gamesPlayed: isComplete ? acc.gamesPlayed + 1 : acc.gamesPlayed,
          hoursTotal: acc.hoursTotal + desiredHours,
          hoursPlayed: acc.hoursPlayed + playedHours,
          priorityHours: {
            ...acc.priorityHours,
            [game.priority]: acc.priorityHours[game.priority] + desiredHours,
          },
        };
      },
      {
        gamesTotal: 0,
        gamesPlayed: 0,
        hoursTotal: 0,
        hoursPlayed: 0,
        benchedGames: 0,
        benchedHours: 0,
        priorityHours: {
          done: 0,
          'very-low': 0,
          low: 0,
          'medium-low': 0,
          medium: 0,
          'medium-high': 0,
          high: 0,
          'very-high': 0,
        },
      }
    );
  }, [games]);

  return (
    <Container maxWidth="lg" style={{ paddingTop: 12, paddingBottom: 24 }}>
      <Paper elevation={1}>
        <Paper elevation={2}>
          <Box p={2}>
            <LinearProgressWithLabel
              value={stats.gamesTotal !== 0 ? (100 * stats.gamesPlayed) / stats.gamesTotal : 0}
              leftLabel={'Games'}
              rightLabel={`${stats.gamesPlayed}/${stats.gamesTotal} (${Math.round(
                (100 * stats.gamesPlayed) / stats.gamesTotal
              )}%)`}
            />
          </Box>
          <Box p={2}>
            <LinearProgressWithLabel
              value={stats.hoursTotal !== 0 ? (100 * stats.hoursPlayed) / stats.hoursTotal : 0}
              leftLabel={'Hours'}
              rightLabel={`${Math.round(stats.hoursPlayed)}/${Math.round(stats.hoursTotal)} (${Math.round(
                (100 * stats.hoursPlayed) / stats.hoursTotal
              )}%)`}
            />
          </Box>
          <Box p={1}>
            <Box display="flex" justifyContent="space-between">
              {Object.entries(stats.priorityHours).map(([priority, hours]) => {
                if (priority === 'done') return null;
                return (
                  <Box p={1} display="flex" key={priority}>
                    <Typography variant="body2" color="textSecondary">
                      {getPriorityDisplay(priority as PriorityEnum)}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {`: ${hours} hrs`}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Paper>
      </Paper>
    </Container>
  );
};

const avatarColumn: Column<ParsedGameResponse> = {
  field: 'cover_url',
  render: (rowData) => <img src={rowData.cover_url} style={{ width: 50 }} alt={'cover'} />,
  editable: 'never',
  filtering: false,
  emptyValue: (
    <Avatar style={{ borderRadius: 0, width: 50, height: 50 }}>
      <Games />
    </Avatar>
  ),
};
const nameColumn: Column<ParsedGameResponse> = {
  title: 'Name',
  field: 'name',
  editable: 'never',
  filtering: false,
  cellStyle: () => ({
    fontSize: '20px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 'bold',
    textAlign: 'center',
  }),
  customFilterAndSearch: (term, rowData) => {
    return (
      rowData.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) !== -1 ||
      rowData.platforms
        .map((p) => `${p.name.toLocaleLowerCase()} ${p.abbreviation ? ` (${p.abbreviation.toLocaleLowerCase()})` : ''}`)
        .join(', ')
        .indexOf(term.toLocaleLowerCase()) !== -1
    );
  },
};
const getRatingColor = (rating?: number): string => {
  if (!rating) return '#000';
  const colors = [
    '#d9534f',
    '#dc5e4f',
    '#df684f',
    '#e17250',
    '#e47b50',
    '#e68550',
    '#e98e4f',
    '#eb974f',
    '#eda04f',
    '#efa94e',
    '#eaae4f',
    '#dcb050',
    '#cfb252',
    '#c1b353',
    '#b3b555',
    '#a4b656',
    '#95b758',
    '#84b759',
    '#72b85b',
    '#5cb85c',
  ];
  return colors[Math.ceil(rating / 5) - 1];
};
const ratingColumn: Column<ParsedGameResponse> = {
  title: 'Rating',
  field: 'total_rating',
  editable: 'never',
  filtering: false,
  cellStyle: (data, rowData) => ({
    color: getRatingColor(rowData.total_rating),
    fontWeight: 'bold',
    fontSize: '34px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  }),
  emptyValue: '-',
};
const releaseYearColumn: Column<ParsedGameResponse> = {
  title: 'Year',
  field: 'first_release_date',
  editable: 'never',
  filtering: false,
  render: (rowData) =>
    rowData.first_release_date
      ? new Date(rowData.first_release_date * 1000).toISOString().slice(0, 4).replace(/-/g, '\u2011')
      : '-',
  emptyValue: '-',
  cellStyle: () => ({
    fontWeight: 'bold',
    fontSize: '20px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  }),
};

const getHltbColor = (hltb?: number): string => {
  if (!hltb) return '#000';
  const colors = [
    '#5cb85c',
    '#72b85b',
    '#84b759',
    '#95b758',
    '#a4b656',
    '#b3b555',
    '#c1b353',
    '#cfb252',
    '#dcb050',
    '#eaae4f',
    '#efa94e',
    '#eda04f',
    '#eb974f',
    '#e98e4f',
    '#e68550',
    '#e47b50',
    '#e17250',
    '#df684f',
    '#dc5e4f',
    '#d9534f',
  ];
  if (hltb < 5) return colors[0];
  if (hltb < 10) return colors[1 + Math.floor((hltb - 5) / (5 / 2))];
  if (hltb < 20) return colors[3 + Math.floor((hltb - 10) / (10 / 4))];
  if (hltb < 100) return colors[7 + Math.floor((hltb - 20) / (80 / 13))];
  return colors[colors.length - 1];
};
const hltbColumn: Column<ParsedGameResponse> = {
  title: 'HLTB',
  field: 'relevantHltb',
  editable: 'never',
  filtering: false,
  cellStyle: (data, rowData) => ({
    color: getHltbColor(rowData.relevantHltb),
    fontWeight: 'bold',
    fontSize: '20px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  }),
  render: (rowData) => (rowData.relevantHltb ? `${rowData.relevantHltb}h` : '-'),
  emptyValue: '-',
};
const getStatusDisplay = (status: StatusEnum): string => {
  if (status === 'not-started') return 'Not\u00A0Started';
  if (status === 'started') return 'Started';
  if (status === 'base-complete') return 'Complete';
  if (status === 'full-complete') return '100%';
  if (status === 'benched') return 'Benched';
  return '-';
};
const statusColumn: Column<ParsedGameResponse> = {
  title: 'Status',
  field: 'status',
  defaultFilter: ['not-started', 'started'],
  cellStyle: () => ({
    fontSize: '16px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  }),
  lookup: {
    'not-started': 'Not\u00A0Started',
    started: 'Started',
    'base-complete': 'Complete',
    'full-complete': '100%',
    benched: 'Benched',
  },
};
const desiredStatusColumn: Column<ParsedGameResponse> = {
  title: 'Desired',
  field: 'desired_status',
  cellStyle: () => ({
    fontSize: '16px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  }),
  lookup: {
    'not-started': 'Not\u00A0Started',
    started: 'Started',
    'base-complete': 'Complete',
    'full-complete': '100%',
    benched: 'Benched',
  },
  render: (rowData) => (rowData.status === 'benched' ? '-' : getStatusDisplay(rowData.desired_status)),
};
const myRatingColumn: Column<ParsedGameResponse> = {
  title: 'My Rating',
  field: 'my_rating',
  filtering: false,
  cellStyle: (data, rowData) => ({
    color: getRatingColor(rowData.my_rating),
    fontWeight: 'bold',
    fontSize: '34px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  }),
  emptyValue: '-',
};
const getPriorityColor = (priority: PriorityEnum): string => {
  if (priority === 'very-low') return '#d9534f';
  if (priority === 'low') return '#c46351';
  if (priority === 'medium-low') return '#af7453';
  if (priority === 'medium') return '#9a8555';
  if (priority === 'medium-high') return '#859657';
  if (priority === 'high') return '#70a759';
  return '#5cb85c';
};
const getPriorityDisplay = (priority: PriorityEnum): string => {
  if (priority === 'very-low') return 'Very Low';
  if (priority === 'low') return 'Low';
  if (priority === 'medium-low') return 'Medium Low';
  if (priority === 'medium') return 'Medium';
  if (priority === 'medium-high') return 'Medium High';
  if (priority === 'high') return 'High';
  return 'Very High';
};
const priorityColumn: Column<ParsedGameResponse> = {
  title: 'Priority',
  field: 'priority',
  cellStyle: (data, rowData) => ({
    color:
      rowData.status === 'benched'
        ? '#d9534f'
        : rowData.status === rowData.desired_status
        ? '#5cb85c'
        : getPriorityColor(rowData.priority),
    fontWeight: 'bold',
    fontSize: '20px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  }),
  customSort: (a, b) => {
    const priorities = ['done', 'very-low', 'low', 'medium-low', 'medium', 'medium-high', 'high', 'very-high'];
    const aIndex = priorities.indexOf(a.priority);
    const bIndex = priorities.indexOf(b.priority);
    return aIndex - bIndex;
  },
  lookup: {
    done: 'Done',
    'very-low': 'Very Low',
    low: 'Low',
    'medium-low': 'Medium Low',
    medium: 'Medium',
    'medium-high': 'Medium High',
    high: 'High',
    'very-high': 'Very High',
  },
  render: (rowData) =>
    rowData.status === 'benched' ? (
      <Close />
    ) : rowData.priority === 'done' ? (
      <Check />
    ) : (
      getPriorityDisplay(rowData.priority)
    ),
};
const columns = [
  avatarColumn,
  ratingColumn,
  nameColumn,
  releaseYearColumn,
  hltbColumn,
  priorityColumn,
  statusColumn,
  desiredStatusColumn,
  myRatingColumn,
];

interface DetailPanelProps {
  game: ParsedGameResponse;
  updateGame: (game: GameResponse) => void;
}
const DetailPanel = ({ game, updateGame }: DetailPanelProps) => {
  const [refreshLoading, setRefreshLoading] = useState(false);
  const refreshGame = (gameId: number) => {
    if (refreshLoading) return;
    setRefreshLoading(true);
    axios
      .post<GameResponse>(`${API_URL}/igdb/add-game/${gameId}`)
      .then((response) => updateGame(response.data))
      .finally(() => setRefreshLoading(false));
  };

  return (
    <Container maxWidth="sm">
      <Grid container spacing={1} style={{ margin: 20 }}>
        <Grid item xs={12}>
          <Grid container alignContent="center" alignItems="center">
            <Typography variant="h6">{game.name}</Typography>
            <IconButton onClick={() => refreshGame(game.id)} disabled={refreshLoading} style={{ marginLeft: 16 }}>
              <Refresh />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" component="span">
            {'ID: '}
          </Typography>
          <Typography component="span" style={{ fontWeight: 'bold' }}>
            {game.id}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" component="span">
            {'Platforms: '}
          </Typography>
          <Typography component="span" style={{ fontWeight: 'bold' }}>
            {game.platforms.map((platform) => platform.abbreviation || platform.name).join(', ')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" component="span">
            {'Total Rating: '}
          </Typography>
          <Typography component="span" style={{ fontWeight: 'bold' }}>
            {game.total_rating ? `${game.total_rating} (${game.total_rating_count})` : '-'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" component="span">
            {'IGDB Rating: '}
          </Typography>
          <Typography component="span" style={{ fontWeight: 'bold' }}>
            {game.rating ? `${game.rating} (${game.rating_count})` : '-'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" component="span">
            {'Aggregated Rating: '}
          </Typography>
          <Typography component="span" style={{ fontWeight: 'bold' }}>
            {game.aggregated_rating ? `${game.aggregated_rating} (${game.aggregated_rating_count})` : '-'}
          </Typography>
        </Grid>
        {game.hltbMain ? (
          <Grid item xs={12}>
            <Typography variant="body2" component="span">
              {`${game.hltbMainLabel}: `}
            </Typography>
            <Typography component="span" style={{ fontWeight: 'bold' }}>
              {`${game.hltbMain}h`}
            </Typography>
          </Grid>
        ) : null}
        {game.hltbMainExtra ? (
          <Grid item xs={12}>
            <Typography variant="body2" component="span">
              {`${game.hltbMainExtraLabel}: `}
            </Typography>
            <Typography component="span" style={{ fontWeight: 'bold' }}>
              {`${game.hltbMainExtra}h`}
            </Typography>
          </Grid>
        ) : null}
        {game.hltbCompletionist ? (
          <Grid item xs={12}>
            <Typography variant="body2" component="span">
              {`${game.hltbCompletionistLabel}: `}
            </Typography>
            <Typography component="span" style={{ fontWeight: 'bold' }}>
              {`${game.hltbCompletionist}h`}
            </Typography>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Typography variant="body2" component="span">
            {'First Release Date: '}
          </Typography>
          <Typography component="span" style={{ fontWeight: 'bold' }}>
            {game.first_release_date
              ? `${new Date(game.first_release_date * 1000).toISOString().slice(0, 10).replace(/-/g, '\u2011')}`
              : '-'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" component="span">
            {'IGDB Link: '}
          </Typography>
          <Typography component="span" style={{ fontWeight: 'bold' }}>
            <Link href={`https://igdb.com/games/${game.slug}`} rel="noreferrer" target="_blank">{`/${game.slug}`}</Link>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

interface GameTableProps {
  games: ParsedGameResponse[];
  updateGame: (updatedGame: GameResponse) => void;
  deleteGame: (deletedGame: GameResponse) => void;
  setOpenAddGame: (open: boolean) => void;
}
const GameTable = ({ games, updateGame, deleteGame, setOpenAddGame }: GameTableProps) => {
  const editable = {
    isDeletable: () => true,
    onRowUpdate: async (updatedGame: GameResponse, oldGame?: GameResponse) => {
      return axios
        .patch<GameResponse>(`${API_URL}/games/${updatedGame.id}`, {
          priority: oldGame && oldGame.priority !== updatedGame.priority ? updatedGame.priority : undefined,
          status: oldGame && oldGame.status !== updatedGame.status ? updatedGame.status : undefined,
          desired_status:
            oldGame && oldGame.desired_status !== updatedGame.desired_status ? updatedGame.desired_status : undefined,
          my_rating: oldGame && oldGame.my_rating !== updatedGame.my_rating ? updatedGame.my_rating : undefined,
        })
        .then((response) => updateGame(response.data));
    },
    onRowDelete: async (deletedGame: GameResponse) => {
      return axios.delete(`${API_URL}/games/${deletedGame.id}`).then(() => deleteGame(deletedGame));
    },
  };

  const addGameFreeAction: Action<ParsedGameResponse> = {
    icon: () => <AddBox style={{ color: 'green' }} />,
    tooltip: 'Add Game',
    isFreeAction: true,
    onClick: () => setOpenAddGame(true),
  };
  const actions = [addGameFreeAction];

  return (
    <Container maxWidth="lg">
      <Paper>
        <IconMaterialTable
          columns={columns}
          data={games}
          editable={editable}
          options={{
            sorting: true,
            pageSize: 10,
            actionsColumnIndex: -1,
            filtering: true,
            pageSizeOptions: [5, 10, 20, 50, 100, 500],
          }}
          title="Games"
          detailPanel={(game) => <DetailPanel game={game} updateGame={updateGame} />}
          actions={actions}
          onRowClick={(event, rowData, togglePanel) => togglePanel && togglePanel()}
        />
      </Paper>
    </Container>
  );
};

const parseGame = (game: GameResponse): ParsedGameResponse => {
  const hltbEntry = game.hltbEntries.find((hltbEntry) => hltbEntry.chosen);

  const platformString = game.platforms
    .map((p) => `${p.name.toLocaleLowerCase()} ${p.abbreviation ? ` (${p.abbreviation.toLocaleLowerCase()})` : ''}`)
    .join(', ');

  const relevantHltb =
    game.desired_status === 'full-complete' && hltbEntry?.gameplay_completionist
      ? Number(hltbEntry.gameplay_completionist)
      : hltbEntry?.gameplay_main
      ? Number(hltbEntry.gameplay_main)
      : undefined;

  return {
    ...game,
    relevantHltb,
    hltbMainLabel: hltbEntry?.gameplay_main_label || 'Main Story',
    hltbMainExtraLabel: hltbEntry?.gameplay_main_extra_label || 'Main + Extra',
    hltbCompletionistLabel: hltbEntry?.gameplay_completionist_label || 'Completionist',
    hltbMain: hltbEntry?.gameplay_main ? Number(hltbEntry.gameplay_main) : undefined,
    hltbMainExtra: hltbEntry?.gameplay_main_extra ? Number(hltbEntry.gameplay_main_extra) : undefined,
    hltbCompletionist: hltbEntry?.gameplay_completionist ? Number(hltbEntry.gameplay_completionist) : undefined,
    platformString,
  };
};
const parseGames = (games: GameResponse[]): ParsedGameResponse[] => {
  return games.map((game) => parseGame(game));
};

export const App = () => {
  const [openAddGame, setOpenAddGame] = useState(false);
  const [games, setGames] = useState<ParsedGameResponse[]>([]);
  const [search, setSearch] = useState('');
  const [searchedGames, setSearchedGames] = useState<SearchedGameResponse[]>([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);

  const updateGame = (updatedGame: GameResponse) => {
    setGames(
      games.map((game) => {
        return game.id === updatedGame.id ? parseGame(updatedGame) : game;
      })
    );
  };
  const deleteGame = (deletedGame: GameResponse) => {
    setGames(games.filter((game) => game.id !== deletedGame.id));
  };
  const addGame = (addedGame: GameResponse) => {
    setGames([...games, parseGame(addedGame)]);
  };

  const searchGames = (event: FormEvent) => {
    event.preventDefault();
    if (searchLoading || search.length < 3) return;
    setSearchLoading(true);
    axios
      .post<SearchedGameResponse[]>(`${API_URL}/igdb/search-games/${encodeURIComponent(search)}`)
      .then((response) => setSearchedGames(response.data))
      .finally(() => setSearchLoading(false));
  };

  const addGameDb = (searchedGameId: number) => {
    if (addLoading) return;
    setAddLoading(true);
    axios
      .post<GameResponse>(`${API_URL}/igdb/add-game/${searchedGameId}`)
      .then((response) => addGame(response.data))
      .finally(() => setAddLoading(false));
  };

  useEffect(() => {
    axios.get<GameResponse[]>(`${API_URL}/games`).then((response) => {
      setGames(parseGames(response.data));
    });
  }, []);

  return (
    <>
      <Dialog open={openAddGame} onClose={() => setOpenAddGame(false)} maxWidth={'xl'} scroll={'paper'}>
        <DialogTitle>
          Add Game
          <Grid container spacing={1}>
            <Grid item>
              <List>
                <ListItem>
                  <form onSubmit={searchGames}>
                    <Grid container spacing={1} alignItems="flex-end">
                      <Grid item>
                        <Search />
                      </Grid>
                      <Grid item>
                        <TextField
                          id="input-with-icon-grid"
                          label="Search IGDB"
                          value={search}
                          onChange={(event) => setSearch(event.target.value)}
                          disabled={searchLoading}
                        />
                      </Grid>
                      <Grid item>
                        <Button type={'submit'} color="primary" disabled={searchLoading}>
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <List>
                {searchedGames.map((searchedGame) => {
                  return (
                    <ListItem key={searchedGame.id}>
                      <ListItemAvatar>
                        {searchedGame.cover ? (
                          <Avatar src={searchedGame.cover.url} style={{ borderRadius: 0 }} />
                        ) : (
                          <Avatar style={{ borderRadius: 0 }}>
                            <Games />
                          </Avatar>
                        )}
                      </ListItemAvatar>
                      <ListItemText primary={searchedGame.name} style={{ marginRight: 16 }} />
                      <ListItemSecondaryAction>
                        {!!games.find((game) => game.id === searchedGame.id) ? (
                          <IconButton disabled={true} style={{ color: 'green' }}>
                            <Check />
                          </IconButton>
                        ) : (
                          <IconButton onClick={() => addGameDb(searchedGame.id)} disabled={addLoading}>
                            <AddBox />
                          </IconButton>
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAddGame(false)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <GameProgress games={games} />

      <GameTable games={games} updateGame={updateGame} deleteGame={deleteGame} setOpenAddGame={setOpenAddGame} />
    </>
  );
};
